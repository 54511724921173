.quick-actions {
	flex-grow: 1;
	height: 100%;
	text-align: center;

	.quick-actions-grid {
		margin: auto;
		max-width: 96%;

		a {
			min-width: 100%;
		}

		.quick-action-paper {
			cursor: pointer;
			font-size: 1.4rem;
			height: 100%;
			margin: auto;
			padding: 10px 6px;
			transition: all 0.2s ease-in-out;
			width: 100%;
			// Center the content vertically and horizontally
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			&.quick-action-paper-icon {
				justify-content: flex-start;
			}

			.quick-action-text {
				font-size: 1rem;
				font-weight: 500;
				letter-spacing: -0.015rem;
				line-height: 150%;
				margin: min(5% / 4, 20px) auto;
				padding: 15px 0;
				text-align: center;
				width: 100%;

				@media screen and (max-width: 940px) {
					font-size: 1rem;
				}
			}
		}

		.quick-actions-icon {
			margin: min(5% / 4 * 2.5, 45px) auto;
			width: 100%;
			height: 100%;
			min-height: 5rem;

			svg {
				font-size: 5rem;
			}
		}
	}

	// Floating close button in top right that sets display to false
	.quick-actions-close {
		background-color: #747477;
		border-radius: 50%;
		color: #fff;
		cursor: pointer;
		margin-right: 75%;

		.MuiIconButton-root {
			svg {
				font-size: 0.8rem;
			}
		}
	}
}
