.footer-title {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	font-family: 'Poppins', sans-serif;
	font-size: calc((1.5 - 1) * 1.2vw + 1rem) !important;
	font-style: normal;
	font-weight: 700 !important;
	letter-spacing: 0em;
	line-height: 1.8 !important;
	text-transform: none;
	white-space: pre-wrap;
}

.footer-action {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	color: #dbdbdb;
	cursor: pointer;
	font-family: 'Poppins', sans-serif;
	font-size: calc((1 - 1) * 1.2vw + 1rem) !important;
	font-style: normal;
	font-weight: 400 !important;
	letter-spacing: 0em;
	line-height: 1.8 !important;
	text-decoration-skip-ink: auto;
	text-decoration: underline;
	text-transform: none;
	text-underline-offset: 0.2em;
	word-wrap: break-word;
}
