// Search bar itself
.search-bar-container {
	margin: auto !important;
}

.search-bar {
	border-radius: 80px;
	transition: all 0.2s ease-in-out;

	svg {
		color: #fff;
		font-size: 1.25rem;
	}

	.MuiInputBase-input {
		color: white;
		padding-left: 24px !important;
	}

	::-webkit-input-placeholder {
		opacity: 0.9 !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: #ffffff00 !important;
	}

	.MuiOutlinedInput-root {
		padding-right: 9px !important;
		max-height: 48px;
	}
}

.search-filters {
	margin: 20px auto auto;
	transition: all 0.5s ease-in-out;
	width: 72%;

	.MuiGrid-container:not(:last-child) {
		margin-bottom: 2%;
	}

	.MuiGrid-root {
		text-align: center;

		&.search-checkbox-container {
			color: #fff !important;
			margin: auto max(calc(7.5% / 4 * 2.5), 20px);
		}
	}

	.search-filter-options {
		margin: 0.5% auto auto;
	}

	&.search-filters-hidden {
		display: none;
		transition: all 0.5s ease-in-out;
	}

	@media screen and (max-width: 940px) {
		width: auto;

		.MuiGrid-root.search-checkbox-container {
			margin: auto max(calc(5.5% / 4 * 2.5), 10px);
		}
	}
}

.search-filters-tags-showcase {
	background-color: #2c2c33 !important;
	border-radius: 5px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	color: #ffffff;
	margin: auto 0.5%;
	max-height: 36px;
	transition: all 0.25s ease-in-out;

	.MuiChip-label {
		overflow: initial;
		padding: 4px 12px;
	}

	svg {
		color: #ffffff;
		transition: all 0.25s ease-in-out;
	}

	&.search-tags-library {
		background-color: transparent !important;
		color: #fff;
		box-shadow: none;
	}

	&.tag-showcase-active {
		background-color: #ffe456 !important;
		color: #000;

		svg {
			transform: rotate(45deg);
			transition: all 0.25s ease-in-out;
		}
	}

	&.broken-tag {
		background-color: #fa7373 !important;
	}

	&.qa-test-tag {
		background-color: #8acb56 !important;
		color: #000;

		svg {
			color: #000;
		}
	}

	&.nsfw-tag {
		background-color: #a55 !important;
		color: #000;

		svg {
			color: #000;
		}
	}

	&:hover {
		background-color: #99ffe9 !important;
		color: #000;
		transition: all 0.1s ease-in-out;

		svg {
			color: #000;
		}
	}
}

.search-clear-button {
	color: #fff !important;

	@media screen and (max-width: 425px) {
		display: none;
	}
}

// Navbar specific styles
.navbar-appbar {
	.search-bar {
		margin: auto;
		width: 98%;

		.MuiInputAdornment-root {
			@media screen and (max-width: 375px) {
				padding-right: 8%;
			}
			@media screen and (max-width: 320px) {
				padding-right: 30%;
			}
		}
	}
}

// Phase dependent styles
.phase-dependent-switch {
	min-height: 56px;
	min-width: 112px;
	padding: 0 14px;
	margin: auto 1%;
	overflow: inherit;

	.MuiSwitch-switchBase {
		transform: translateX(0px);
		padding: 0 !important;

		&.Mui-checked {
			transform: translateX(56px);
		}
	}

	.MuiSwitch-track {
		border-radius: 30px 30px 30px 30px / 40px 40px 40px 40px;
		opacity: 0.5;
	}

	svg {
		min-height: 56px;
		min-width: 56px;
	}

	.phase-dependent-button {
		border-radius: 50%;
		padding: 25%;
	}
}
