.profile {
	label {
		color: #fff;
	}
}

.profile-char-count {
	color: rgb(255, 255, 255);
	font-size: 0.8rem;
	margin-bottom: -1.2rem;
	text-align: right;
	width: calc(100% - 12px);
}

.profile-layout-content {
	display: flex;
	max-width: 80%;
	padding-left: 0px !important;
	padding-right: 0px !important;
	width: 80%;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		max-width: 98%;
	}
}
