@import './admin-styles.scss';
@import './asset-styles.scss';
@import './flag-styles.scss';
@import './footer-styles.scss';
@import './home-styles.scss';
@import './learn-styles.scss';
@import './library-styles.scss';
@import './my-storage-styles.scss';
@import './navbar-styles.scss';
@import './page-styles.scss';
@import './profile-styles.scss';
@import './sav-styles.scss';
@import './search-bar-styles.scss';
@import './skeleton-styles.scss';
@import './social-share.scss';
@font-face {
	font-family: 'Circe';
	src:
		url('/fonts/crc/hinted-Circe-Regular.woff2') format('woff2'),
		url('/fonts/crc/hinted-Circe-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

:root {
	--toastify-color-light: #ffffff;
	--toastify-color-progress-info: #325956;
	--toastify-icon-color-info: rgb(0, 0, 0);
	--toastify-text-color-info: rgb(0, 0, 0);
	--toastify-text-color-light: #000000;
	--toastify-color-progress-error: #ff0000;
	--toastify-icon-color-error: rgb(255, 0, 0);
	--toastify-text-color-error: rgb(255, 0, 0);
}

// Import react-toastify styles
@import '~react-toastify/dist/ReactToastify.min.css';

.viewer {
	background-color: #181e2770;
	border-radius: 28px;
	height: 550px;
	width: 700px;
}

html,
body {
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	font-size: 1rem;
	height: 100%;
	margin: 0;
	// overflow-x: hidden;
	// overflow-y: hidden;
	padding: 0;
	scroll-behavior: smooth;
	// Add anti-aliasing to web
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

main {
	flex: 1 0 auto;
	overflow-x: hidden;
}

.main-content {
	display: flex !important;
}

br {
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

a {
	text-decoration: none;
}

svg path {
	-webkit-font-smoothing: antialiased;
	image-rendering: optimizeQuality;
	shape-rendering: geometricPrecision;
	text-rendering: geometricPrecision;
}

#__next {
	display: flex;
	flex-direction: column;
	height: 100%;
}

#content {
	height: 100%;
	min-height: 50px;
	scroll-behavior: smooth !important;
}

img {
	-moz-user-drag: none;
	-webkit-user-drag: none;
}

.infinite-scroll-component__outerdiv {
	width: 100%;
}

/* width */
::-webkit-scrollbar {
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(73, 73, 73);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(79, 79, 79);
	border-radius: 10px;
}

@keyframes moveGradientRightToLeft {
	0% {
		background-position: right;
	}

	50% {
		background-position: left;
	}

	100% {
		background-position: right;
	}
}

// Global Vuplex styles
.in-app-browser {
	border-radius: 0 !important;
	transition: all 0.1s ease-in-out;

	// Transparent background
	// background-color: transparent !important;

	main {
		margin: auto;
		padding: auto;
	}

	.in-app-gradient-background {
		background: linear-gradient(180deg, #18181c 5%, #00352a90 100%);
	}

	.navbar-container {
		padding-left: 3%;
		padding-right: 3%;

		@media screen and (max-width: 1080px) {
			padding-left: 2%;
			padding-right: 2%;
		}
	}

	// Scrollbar:
	overflow-y: overlay !important;

	// &::-webkit-scrollbar {
	// 	width: 0rem;
	// }

	// &::-webkit-scrollbar-thumb {
	// 	background-color: #707070;
	// 	border-radius: 10px;
	// 	border: 1px solid #00eab725;
	// }

	// Navbar:
	.navbar-appbar {
		border-radius: 0 !important;

		.search-bar {
			background-color: #5a5a6d99 !important;
		}
	}

	// Material-UI components
	.MuiSkeleton-root {
		background-color: #ffffff80;
	}
}

.mpx-icons {
	margin: auto;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

// Temporary stiles
.support-dialog {
	.MuiPaper-root {
		border-radius: 8px !important;
		padding: 0 !important;
	}
}
