.my-storage {
	width: 100%;
	text-align: center;
	padding-bottom: 2%;
}

.my-storage-subsection {
	position: relative;
	margin-bottom: 5%;
}

.my-storage-header {
	font-size: 2em;
}

.my-storage-near-cap {
	color: #ff9e9e !important;
}

.my-storage-subtext {
	padding-top: 0;
}
