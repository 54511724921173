.asset-subsection-stack {
	position: relative;
	margin: 0.5% auto;
	transition: all 0.2s ease-in-out;

	&.my-storage-container {
		text-align: center;
	}
}

.asset-subsection-stack-header {
	margin: min(calc(5% / 5), 20px) auto !important;
	max-width: 96%;
	position: relative;

	// Text title for the asset subsection splits
	.asset-subsection-title {
		align-items: center;
		display: flex;
		font-size: 1.5rem;
		margin: auto;
		text-align: left;

		// Center the header
		&.asset-subsection-title-center {
			position: sticky;
			margin: auto;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
		}

		h2 {
			margin: 0;
		}

		.MuiTypography-root {
			font-size: 1.5rem !important;
		}
	}

	// Sort for the asset subsection splits
	.asset-subsection-sort-container {
		position: relative;
		padding-top: min(1vh, 8px);
		// Align right
		right: 0;

		.asset-sort-select {
			align-items: center;
			background-color: #2c2c33 !important;
			border-radius: 20px !important;
			color: #ffffff !important;
			font-size: 1rem !important;
			font-weight: 700;
			height: 2.6rem;
			line-height: 24px !important;
			max-width: 100%;
			padding-left: 12px;
			padding-right: 12px;
			text-align: center;
			transition: all 0.5s ease-in-out;

			@media screen and (max-width: 640px) {
				font-size: 0.9rem !important;
			}
			.MuiOutlinedInput-notchedOutline {
				border: 0px;
			}
		}
	}
}

// Asset grid display:
.asset-display {
	margin: 3% auto min(4%, 50px) !important;

	.asset-grid-item {
		aspect-ratio: 1 / 1;
	}

	&.asset-display-see-more {
		margin: 3% auto 1%;
	}

	// Rounded corners
	&.asset-display-rounded {
		border-radius: 20px;
		overflow: auto;
	}

	// Scrollbar related styling
	scrollbar-width: auto;
	scrollbar-color: #787878 #ffffff;
	position: relative;

	// Scroll bar itself
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: 425px) {
		margin: auto auto 1%;
	}
}

// Horizontal scroll buttons:
.asset-horizontal-scroll-grid-container {
	position: relative;

	@media screen and (max-width: 940px) {
		&:hover {
			.asset-horizontal-scroll-button {
				background-color: #13141720;
			}
		}
	}
}

.asset-horizontal-scroll-button {
	background-color: #13141720;
	border-radius: 5% !important;
	flex-grow: 0;
	height: 100%;
	max-height: 100% !important;
	// max-width: max(calc(5% + 2em), 80px);
	min-width: min(5%, 80px);
	overflow: hidden;
	padding: 0 !important;
	position: absolute !important;
	top: 0;
	transition: all 0.2s ease-in-out;
	z-index: 1001;

	svg {
		color: #fff;
		font-size: 2em;
	}

	@media screen and (max-width: 940px) {
		background-color: initial;
		border-radius: 1%;

		svg {
			font-size: 1.5em;
		}
	}

	&:hover {
		background-color: #13141795 !important;
		transition: all 0.2s ease-in-out;

		svg {
			color: #b1ffee;
		}

		@media screen and (max-width: 940px) {
			background-color: #13141790;

			display: inline-flex;
		}
	}

	&.asset-horizontal-scroll-left {
		left: 0;
		margin-left: auto;
	}

	&.asset-horizontal-scroll-right {
		margin-right: auto;
		right: 0;
		transform: rotate(180deg);
	}
}

// Asset card:
.asset-card {
	// aspect-ratio: 1 / 1;
	height: 100%;
	max-width: 100%;
	position: relative;
	width: 100%;

	&:hover {
		transition: all 0.2s ease-in-out;

		.asset-media-overlay-title .MuiTypography-root {
			white-space: pre-wrap;
		}

		.asset-card-overlay {
			display: block;
		}
	}

	.asset-card-overlay {
		display: none;
		max-width: -moz-fit-content;
		max-width: fit-content;
	}

	.asset-card-action-area {
		height: 100% !important;
	}

	// Asset card thumbnail/image
	.asset-thumbnail {
		cursor: pointer !important;
		display: inline-block;
		height: 100% !important;
		max-width: 100% !important;
		object-fit: cover !important;
		object-position: center !important;
		transition: all 0.3s ease-in-out !important;
		width: 100%;

		background: rgb(53, 53, 61);
		background: -moz-linear-gradient(
			180deg,
			rgba(53, 53, 61, 1) 0%,
			rgba(75, 75, 87, 1) 55%,
			rgba(75, 75, 87, 1) 100%
		);
		background: -webkit-linear-gradient(
			180deg,
			rgba(53, 53, 61, 1) 0%,
			rgba(75, 75, 87, 1) 55%,
			rgba(75, 75, 87, 1) 100%
		);
		background: linear-gradient(180deg, rgba(53, 53, 61, 1) 0%, rgba(75, 75, 87, 1) 55%, rgba(75, 75, 87, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#35353d",endColorstr="#4b4b57",GradientType=1);

		img {
			object-fit: cover;
			transition: all 0.5s ease-in-out;
		}
	}

	border-radius: 0px !important;
	background: rgb(221, 221, 221);
	background: -moz-radial-gradient(circle, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%);
	background: -webkit-radial-gradient(circle, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%);
	background: radial-gradient(circle, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#EEB68E",endColorstr="#1a1a1f",GradientType=1);

	.asset-thumbnail-empty {
		align-items: center;
		display: flex;
		font-size: 1rem;
		height: 100%;
		justify-content: center;
		width: 100%;

		white-space: pre-wrap;
		line-height: 1.5em;
		letter-spacing: 0.1rem;
		opacity: 0.75;

		background: #2c2c33;

		.asset-thumbnail-empty-text {
			text-align: center;
		}
	}

	// Asset cards overlay
	.asset-media-overlay {
		-webkit-font-smoothing: antialiased;
		color: #fff;
		cursor: pointer;
		position: absolute !important;
		text-align: center;
		transition: all 0.2s ease-in-out;
		z-index: 1;

		&.asset-media-overlay-title {
			align-items: flex-start;
			bottom: 0;
			color: #fff;
			flex-direction: column;
			font-size: 1rem;
			font-style: normal;
			font-weight: 500;
			height: 100%;
			justify-content: flex-end;
			left: 0;
			letter-spacing: 0.015em;
			line-height: 3.125rem;
			overflow-wrap: break-word;
			overflow: hidden;
			right: initial;
			text-align: left;
			text-overflow: ellipsis;
			text-shadow:
				-1px -1px 0 #000,
				0 -1px 0 #000,
				1px -1px 0 #000,
				1px 0 0 #000,
				1px 1px 0 #000,
				0 1px 0 #000,
				-1px 1px 0 #000,
				-1px 0 0 #000;
			top: initial;
			white-space: nowrap;
			width: 100%;

			// Background gradient
			background: -moz-linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.5046393557422969) 0%,
				rgba(0, 0, 0, 0) 50%,
				rgba(0, 0, 0, 0) 100%
			);
			background: -webkit-linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.5046393557422969) 0%,
				rgba(0, 0, 0, 0) 50%,
				rgba(0, 0, 0, 0) 100%
			);
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.5046393557422969) 0%,
				rgba(0, 0, 0, 0) 50%,
				rgba(0, 0, 0, 0) 100%
			);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=0);

			p {
				margin: 0 0 1rem 1rem; // Adjust margin to bottom left
				padding: 0.5% !important;
			}

			svg {
				color: #00eab7;
				filter: drop-shadow(0px 2px 1px #27272775) drop-shadow(2px 0px 3px #27272775);
				margin: 3%;
			}
		}

		&.asset-media-overlay-title-desktop {
			svg {
				margin: 0 1rem 1rem 0; // Adjust margin to bottom right
			}
		}

		&.asset-media-overlay-buttons {
			bottom: 0;
			font-size: 1.25em;
			min-width: 100%;
			position: absolute;
			padding-right: 1%;

			@media screen and (max-width: 600px) {
				font-size: 1em;
			}

			@media screen and (max-width: 520px) {
				font-size: 0.95em;
			}

			.asset-media-overlay-content {
				min-height: 100%;
				transition: all 0.1s ease-in-out;

				h3 {
					margin: 0;
				}

				svg {
					font-size: 1.3em;
					margin: auto;
				}

				&:hover {
					transition: all 0.1s ease-in-out;
				}
			}
		}
	}

	.asset-media-overlay-delete {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1001;
	}
}

.asset-card-export-overlay {
	position: relative;

	// Add a #0EEBBB30 overlay to the asset card
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #0eebbb30;
		z-index: 1;
	}
}

.asset-display-empty-message {
	font-size: 1.5em;
	font-weight: 500;
	letter-spacing: 0.1rem;
	text-align: center;
}

.asset-thumbnail-modal-container {
	margin: auto;
	position: relative;
	min-width: 100%;
	max-height: 50vh;
	min-height: 50vh !important;
	width: 100%;

	.asset-thumbnail-icons {
		min-width: 100%;
		position: absolute !important;
		z-index: 1001;
	}
}

.asset-model-viewer {
	background: rgb(175, 175, 175);
	background: -moz-radial-gradient(circle, rgba(175, 175, 175, 0.96) 10%, rgba(26, 26, 31, 1) 95%);
	background: -webkit-radial-gradient(circle, rgba(175, 175, 175, 0.96) 10%, rgba(26, 26, 31, 1) 95%);
	background: radial-gradient(circle, rgba(175, 175, 175, 0.96) 10%, rgba(26, 26, 31, 1) 95%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#EEB68E",endColorstr="#1a1a1f",GradientType=1);
	cursor: pointer !important;
	max-height: 50vh;
	min-height: 50vh !important;
	min-width: 90%;
	width: 100%;

	// This would be a shadow-root element, so we need to use ::slotted
	::slotted(.fab) {
		background-color: red;
	}
}

.google-model-viewer-controls {
	height: 100%;
	width: 376px !important;
	max-width: 50%;
	position: absolute !important;
	right: 0;
	top: 0;
	z-index: 1111;
	overflow: auto;
	background-color: #18181c;
	padding: 2%;

	@media screen and (max-width: 425px) {
		display: none;
	}
}

#default-ar-button {
	left: 16px !important;
	right: 0;
}

.asset-thumbnail-modal {
	cursor: default;
	max-height: 60vh;
	min-height: 20vh;
	object-fit: contain;
	width: 100%;
}

.asset-subsection-sorting {
	margin: auto 6% auto auto !important;
}

// Delete icon
.delete-icon {
	cursor: pointer !important;
	color: #fff !important;

	&:hover {
		background-color: #000000be;

		svg {
			color: #f84e4e;
			// On hover, have circle around it
		}
	}
}
