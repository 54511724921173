.social-sharing-close-icon {
	cursor: pointer;
}

// Icons
.social-sharing-icons-list {
	margin-bottom: 5%;
}

.social-sharing-icons {
	svg {
		color: #fff;
	}

	// Specific icon colours to match their respective brand colours
	// &.social-sharing-icon-facebook {
	// 	background-color: #3b5998;

	// 	&.social-sharing-icon-facebook:hover {
	// 		background-color: #2d4373;
	// 	}

	// 	svg {
	// 		fill: #fff;
	// 	}
	// }

	// &.social-sharing-icon-twitter {
	// 	background-color: #55acee;

	// 	&.social-sharing-icon-twitter:hover {
	// 		background-color: #2795e9;
	// 	}

	// 	svg {
	// 		fill: #fff;
	// 	}
	// }

	// &.social-sharing-icon-linkedin {
	// 	background-color: #0077b5;

	// 	&.social-sharing-icon-linkedin:hover {
	// 		background-color: #0062a3;
	// 	}

	// 	svg {
	// 		fill: #fff;
	// 	}
	// }

	// &.social-sharing-icon-pinterest {
	// 	background-color: #bd081c;

	// 	&.social-sharing-icon-pinterest:hover {
	// 		background-color: #a70f16;
	// 	}

	// 	svg {
	// 		fill: #fff;
	// 	}
	// }

	// &.social-sharing-icon-reddit {
	// 	background-color: #ff4500;

	// 	&.social-sharing-icon-reddit:hover {
	// 		background-color: #e63b00;
	// 	}

	// 	svg {
	// 		fill: #fff;
	// 	}
	// }

	// &.social-sharing-icon-email {
	// 	background-color: #838282;

	// 	&.social-sharing-icon-email:hover {
	// 		background-color: #636363;
	// 	}

	// 	svg {
	// 		fill: #fff;
	// 	}
	// }
}
