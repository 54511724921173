// Following is for the Single Asset View (SAV)
.single-asset-view-container {
	.MuiDialog-paper {
		background-image: none;
		border-radius: 20px;
		color: #fff;
		max-width: min(90vw, 1728px);
		padding: 0 !important;

		@media screen and (max-width: 768px) {
			margin: 0 !important;
			max-height: 85%;
			max-width: 95vw;
			width: 100%;
		}
	}

	.MuiGrid-container {
		.MuiFilledInput-root:before {
			border-bottom: 0px !important;
		}
	}

	.MuiGrid-item {
		padding-left: 0.5% !important;
		padding-right: 0.5%;
	}

	.MuiDialogContent-root {
		overflow-x: hidden;
		padding: 0%;
	}

	.MuiDialogTitle-root {
		padding: 1px 24px !important;
	}
}

.single-asset-view {
	margin: 0 auto !important;
	text-align: left;
	width: 100% !important;

	svg {
		color: #fff;
		font-size: 1.5rem;
	}

	.sav-header-title {
		font-size: 2em;
	}

	.sav-header-open-button {
		border-radius: 52.5px;
	}

	// For .sav-header-title-container with child .MuiTextField-root, have .sav-header-title-container have a min-width of 45vw;
	.sav-header-title-container {
		@media screen and (max-width: 970px) {
			font-size: 0.6rem;
		}

		// Material-UI adjustments
		.MuiTextField-root {
			min-width: 45vw;

			.MuiInputBase-root {
				font-size: 2em;
				margin-bottom: auto;
			}
		}
	}

	.sav-section-headers {
		color: #b3b3b3;
		font-size: 1.2rem;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 171.429% */
	}

	.sav-edit-text {
		width: 100%;

		.textfield-char-count {
			text-align: right;

			.MuiTypography-root {
				color: #45454b;
			}
		}

		.MuiInputBase-root {
			background-color: #ebebeb;
			color: #000;
		}

		.MuiFilledInput-root::after {
			border-bottom: 0px !important;
		}
	}

	.sav-metrics-accordion {
		background-image: none !important;
		border-bottom: 2px solid #ffffff65;
		border-radius: 0 !important;
		box-shadow: none !important;
		margin-top: 1% !important;

		.MuiAccordionSummary-expandIconWrapper {
			border-radius: 50%;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: #000000;
				border-radius: 50%;
				transition: all 0.3s ease-in-out;
			}
		}

		.MuiAccordionDetails-root {
			overflow-x: auto;
		}
	}

	.sav-thumbnail-container {
		max-height: 50vh;
		min-height: 30vh;
	}

	.sav-thumbnail-glow {
		filter: blur(50px);
		max-height: 55vh !important;
		transform: scale(1.06);
	}

	.sav-bookmark-active {
		svg {
			color: #00eab7;
		}
	}

	.sav-gmv-edit-buttons {
		border-radius: 4px !important;
		width: 100%;
	}

	// Material-UI
	.MuiGrid-item {
		&.sav-thumbnail-container {
			padding: 0 !important;
		}
	}

	.MuiAutocomplete-endAdornment {
		top: auto;
	}
}

// Styles relating to React toast within SAV
.toast-thumbnail-image {
	max-height: 10vh;
	max-width: 10vw;
	object-fit: cover;
	overflow: hidden;
	position: 'relative';
}
