.asset-skeleton-text {
	background: linear-gradient(90deg, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%) !important;
}

.asset-skeleton-card {
	background: -moz-radial-gradient(circle, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%);
	background: -webkit-radial-gradient(circle, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%);
	background: radial-gradient(circle, rgba(221, 221, 221, 0.46) 0%, rgba(26, 26, 31, 1) 75%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#EEB68E",endColorstr="#1a1a1f",GradientType=1);
}
