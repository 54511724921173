.admin {
	padding: 1rem;

	.admin-grid-container {
		.admin-paper {
			text-align: center;
			transition: all 0.1s ease-in-out;

			&:hover {
				box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.1);
				color: #00eab7;
				transition: all 0.1s ease-in-out;

				// Change SVG color within paper as well
				svg {
					fill: #00eab7;
				}
			}
		}

		.admin-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	hr {
		margin: 2% auto;
	}
}

.admin-item-link {
	text-decoration: none;
}

.admin-category-select {
	.MuiSelect-select {
		border-radius: 20px;
		color: #000;
		padding-bottom: 2%;
		padding-top: 2%;
	}

	svg {
		color: #000;
	}
}
