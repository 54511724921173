// App/navbar itself
.navbar-appbar {
	background-image: none !important;
	// border-radius: 0px 0px 30px 30px !important;
	box-shadow: none !important;
	max-width: 100vw;

	.navbar-container {
		margin: min(3%, 10px) min(calc(8% / 4 * 2.5), 45px) !important;
		max-width: 100vw;
		padding: 0;
	}

	.MuiTypography-root,
	.MuiTab-textColorPrimary {
		border-radius: 50%;
		height: 2em;
		min-width: 2em;
		transition: all 0.1s ease-in-out;

		svg {
			font-size: 2em;

			transition: all 0.1s ease-in-out;
		}

		&.Mui-selected {
			background-color: #eeb68e;
			color: #000000;

			svg {
				font-size: 2em;

				path[data-name='outerFill'] {
					fill: #000000;
				}

				path[data-name='innerFill'] {
					fill: #094d4d;
				}

				path[data-name='emptyFill'] {
					fill: #eeb68e;
				}

				transition: all 0.1s ease-in-out;
			}
		}
	}

	.MuiToolbar-root {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		margin: 1% auto 0;
	}

	.navbar-tab-icon {
		padding-top: 0 !important;
		padding-bottom: 0;
		min-height: 45px;

		@media screen and (max-width: 425px) {
			padding-left: 1% !important;
		}

		.MuiIconButton-root {
			min-height: 45px;
			min-width: 45px;
		}
	}
}

// Profile navbar menu
.navbar-menu {
	.MuiMenu-paper {
		color: #fff;
		filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
		min-width: 325px;

		.MuiMenu-list {
			margin: 5%;
		}
	}

	.navbar-menu-title {
		font-size: 1.2rem;
		max-width: 100%;
		text-align: center;
		text-transform: uppercase;
		// wrap text
		white-space: normal;
		word-wrap: break-word;
	}

	.navbar-menu-item {
		align-content: flex-start;
		border-radius: 40px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin-top: 3%;

		.MuiTypography-root {
			padding: 1% 5%;
		}

		&:hover {
			svg {
				fill: #000;
			}
		}
	}
}

.navbar-logo-container {
	cursor: pointer;

	.MuiTypography-root {
		margin: 0 !important;
	}
}

.navbar-logo {
	padding: 5% !important;
	object-fit: cover;
}
