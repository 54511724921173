.mps-page {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	height: 100%;
	justify-content: center;
	padding-top: 1%;

	& > :first-of-type {
		flex-grow: 1;
		height: 100%;
	}
}
