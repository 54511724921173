.learn-video {
	// 16:9 aspect ratio
	aspect-ratio: 16 / 9;
	height: calc(80vw * 9 / 16);
	position: relative;
	width: 80vw;

	iframe {
		width: 100%;
		height: 100%;
	}
}
