.flag-dialog {
	.flag-a {
		color: #3297f0;
	}

	.flag-button-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
